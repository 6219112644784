import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
// import { ColorsService } from '../../shared/colors/colors.service';
// import { ChartsService } from '../../services/chart.service';
// import { OrderService } from '../../services/orders.service';
declare var $: any;
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from '../../services/event.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Uris } from 'src/app/services/Uris';
import { NgbModal, ModalDismissReasons, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  data: any = [];
  user: any;
  currentUser: any;
  isLogin: any = false;
  titularAlerta: any = "";
  p: any;
  filter: any;
  prueba: any = ''
  loading: any = false
  arrayUsers: any = [];
  ruta: any
  queryParams: any;
  metodoPago: any;
  closeResult: string;
  requiredL: any = false
  showFormFact: any = false
  requiredS: any = false
  dataLogin: any = { email: "", password: "" }
  dataSignUp: any = { company: "", name: "", razon_social: "", num_es: "", tar: "", phone: "", job: "", address: "", neighborhood: "", state: "", postal_code: "", rol: "", email: "", password: "", password_confirmation: "" }
  stepWizard: any = 0;
  login: any = false
  facturations: any = []
  facturationId: any;
  cupon: any;
  special_price_id: any;
  public insForm: FormGroup
  public factForm: FormGroup

  @Output("onRefresh") onRefresh = new EventEmitter<any>();
  @ViewChild('eventModal') private eventModal: any;
  @ViewChild('payModal') private payModal: any;
  // constructor(public colors: ColorsService, private __chartService: ChartsService, private __orderService: OrderService) { }
  constructor(private formBuilder: FormBuilder, private __eventService: EventService, private activatedRoute: ActivatedRoute, private modalService: NgbModal, config: NgbModalConfig, public activeModal: NgbActiveModal) {
    this.ruta = Uris.API_FILES_ENDPOINT
    this.queryParams = this.activatedRoute.snapshot.params.id
    console.log("queryParams -->", this.queryParams);
    this.insForm = formBuilder.group({
      "id": [null],
      "numberPeople": [null],
      "name": [null, Validators.compose([Validators.required])],
      "email": [null, Validators.compose([Validators.required])],
      "user": [[], Validators.compose([Validators.required])],
      "descripcion": [null, Validators.compose([Validators.required])],
      "job": [null, Validators.compose([Validators.required])],
    });
    this.factForm = formBuilder.group({
      "razon_social": [null, Validators.compose([Validators.required])],
      "rfc": [null, Validators.compose([Validators.required])],
      "payment_method": [null, Validators.compose([Validators.required])],
      "uso_cfdi": [null, Validators.compose([Validators.required])],
      "email": [null, Validators.compose([Validators.required])],
      "phone": [null, Validators.compose([Validators.required])],
      "address": [null, Validators.compose([Validators.required])],
		  "postal_code": [null, Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
    this.prueba = 'lorem ipsuasdlj ljlkajdlsj dasudlajsakldjasldjasdjasldjaslkdja lasjdlkasjdaksj dlaksjdalk jdkdjasldjaslkaj lkasj jlkadasjklasjdkl jlkasjdlk aasdl jaslk asjdklasjdlkasjkas alsdj aldjasl djasldalsdlaks '
    if (localStorage.getItem('currentUser')) {
      this.user = (<any>JSON.parse(localStorage.getItem('currentUser')).user) ? (<any>JSON.parse(localStorage.getItem('currentUser')).user) : (<any>JSON.parse(localStorage.getItem('currentUser')).aarc_user);
    }
    this.__eventService.getOne(
      {
        event: {
          id: this.queryParams
        }
      }
    ).subscribe((data) => {
      console.log("Datos -->", data)
      this.data = data.event
    }, e => {
      console.log(e);
    });
  }

  open(content) {
    console.log("CONTENT -->", content)
    this.modalService.open(content, { size: 'lg', centered: true });
  }

  wizard() {
    this.stepWizard += 1
    console.log(this.stepWizard);
  }

  public factFormSave($ev: any) {
    for (let c in this.factForm.controls) {
      this.factForm.controls[c].markAsTouched();
    }
    if (this.factForm.valid) {
      this.loading = true;
      console.log("Form -->", this.factForm.value);
      this.__eventService.addFacturationData({
        facturation_data: this.factForm.value,
        user: { token: this.currentUser.token }
      }).subscribe((data) => {
        console.log("Datos al guardar -->", data);
        if (data.result == "true") {
          this.facturationId = data.facturation_data.id
          console.log("FACTURACION -->", this.facturationId)
          this.wizard()
          // swal({ icon: 'success', title: 'Datos de facturación', text: 'Se registraron correctamen.' });
        } else {
          // swal({ icon: 'error', title: 'Conflictos Al Guardar', text: data.message[0] });
        }
      }, e => {
        // swal({ icon: 'error', title: 'Conflictos Al Guardar', text: 'Hay problemas para guardar información, intentalo más tarde.' });
        // this.loading = false;
      });
    }
  }

  submitLogin() {
    console.log("DATOS -->", this.dataLogin.email, this.dataLogin.password)
    if (!this.dataLogin.email || !this.dataLogin.password) {
      alert("Faltan datos obligatorios")
      this.requiredL = true
    } else {
      this.__eventService.login({ email: this.dataLogin.email, password: this.dataLogin.password }).subscribe(data => {
        console.log("DATA -->", data)
        this.currentUser = data.user
        if (data.result == true) {
          console.log("USER -->", data.user)
          console.log("POR QUE NO ENTRA")
          this.isLogin = true
          this.__eventService.myFacturationData({ admin: { token: data.user.token } }).subscribe(data => {
            this.facturations = data.facturation_data
            for (let i = 0; i < this.facturations.length; i++) {
              this.facturations[i].name = this.facturations[i].razon_social + "-" + this.facturations[i].rfc
            }
          })
          let arrayPricesRol = []
          for (let j = 0; j < this.data.special_prices.length; j++) {
            console.log("SPECIAL PRICES -->", this.data.special_prices[j].rol)
            // if (this.user) {
            if (this.data.special_prices[j].rol == data.user.rol) {
              arrayPricesRol.push(this.data.special_prices[j])
            }
            // }
          }
          console.log("PRECIOS -->", arrayPricesRol)
          this.data.special_prices_select = arrayPricesRol
          console.log("Datos 2 -->", this.data)
        } else {
          alert(data.message)
        }
      })
    }
  }

  signUp() {
    console.log("REGISTRO -->", this.dataSignUp.name, this.dataSignUp.lastname, this.dataSignUp.phone, this.dataSignUp.email, this.dataSignUp.num_es, this.dataSignUp.company, this.dataSignUp.address, this.dataSignUp.password, this.dataSignUp.password_confirmation)
    if (!this.dataSignUp.company || !this.dataSignUp.name || !this.dataSignUp.razon_social || !this.dataSignUp.phone || !this.dataSignUp.job || !this.dataSignUp.address || !this.dataSignUp.neighborhood || !this.dataSignUp.state || !this.dataSignUp.postal_code || !this.dataSignUp.rol || !this.dataSignUp.email || !this.dataSignUp.password || !this.dataSignUp.password_confirmation) {
      alert("Faltan datos obligatorios")
      this.requiredS = true
    } else {
      this.__eventService.sign_up({user: this.dataSignUp}).subscribe(data => {
        console.log("DATA -->", data)
        this.currentUser = data.user
        console.log("USER -->", data.user)
        if (data.result == true) {
          console.log("POR QUE NO ENTRA")
          this.isLogin = true
          let arrayPricesRol = []
          for (let j = 0; j < this.data.special_prices.length; j++) {
            console.log("SPECIAL PRICES -->", this.data.special_prices[j].rol)
            // if (this.user) {
            if (this.data.special_prices[j].rol == data.user.rol) {
              arrayPricesRol.push(this.data.special_prices[j])
            }
            // }
          }
          console.log("PRECIOS -->", arrayPricesRol)
          this.data.special_prices_select = arrayPricesRol
          console.log("Datos 2 -->", this.data)
        } else {
          alert(data.message)
        }
      })
    }
  }

  cancelar() {
    console.log("CANCELAR")
    this.stepWizard = 0
  }
  public valuechangeName(event, index) {
    console.log("EventoName -->", event.srcElement.value);
    this.arrayUsers[index].name = event.srcElement.value

  }
  public valuechangeEmail(event, index) {
    console.log("EventoEmail -->", event.srcElement.value);
    this.arrayUsers[index].email = event.srcElement.value
  }
  public valuechangePuesto(event, index) {
    console.log("EventoEmail -->", event.srcElement.value);
    this.arrayUsers[index].job = event.srcElement.value
  }
  public confirmPersons() {
    console.log('confirm');
    this.arrayUsers = [];
    for (let i = 0; i < this.insForm.value.numberPeople; i++) {
      let example = { email: 'e' + i, name: 'n' + i, job: 'j' + i }
      this.arrayUsers.push(example);
    }
  }
  public cleanFormFact() {
    this.showFormFact = false
    this.factForm.reset()
  }
  public saveSuscription() {
    console.log("Usuarios -->", this.arrayUsers);
    console.log("metodo de pago -->", this.metodoPago);
    // this.spinner.show()
    if (this.arrayUsers == 0) {
      alert("Tienes que agregar minimo a una persona ")
    } else {
      if (!this.metodoPago || !this.special_price_id) {
        alert("Faltan datos obligatorios")
      } else {
        this.__eventService.bulkRegister({
          users: this.arrayUsers,
          event: { id: this.queryParams },
          payment_method: this.metodoPago,
          special_price_id: this.special_price_id,
          coupon: {
            name: this.cupon
          },
          facturation_data_id: this.facturationId,
          user: { token: this.currentUser.token }
        }).subscribe((data) => {
          console.log("Datos al guardar -->", data);
          if (data.result === "true") {
            // swal({ icon: 'success', title: 'Registrado', text: 'Espera un correo con la ficha de pago' }); 
            this.wizard()
            if (this.metodoPago == "paypal") {
              // this.__router.navigate([`checkoutEvent/${id}`]);
            } else {
              this.arrayUsers = [],
                this.metodoPago = null
              this.cupon = null
              this.facturationId = null
            }
          } else {
            // this.spinner.hide()
            alert(data.message)
            // this.loading = false;
          }
          // this.spinner.hide()
          // this.loading = false;
        }, e => {
          // this.spinner.hide()
          alert("Hay problemas para guardar información, intentalo más tarde.")
          // swal({ icon: 'error', title: 'Conflictos Al Guardar', text: 'Hay problemas para guardar información, intentalo más tarde.' });
          // this.loading = false;
        });
      }
    }
  }

}