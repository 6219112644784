import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { EventService } from './services/event.service';
import { HomeComponent } from './routes/home/home.component';
import { Routes, RouterModule, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Http, HttpModule } from '@angular/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'event/:id', component: HomeComponent },
  {
    path: '',
    redirectTo: '/event/:id',
    pathMatch: 'full'
  },
  { path: '**', component: HomeComponent }
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    HomeComponent
  ],
  providers: [EventService, NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule { }
