import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';
import { EventService } from './services/event.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'capaciagroEvents';
}

