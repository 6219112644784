import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';

import { Uris } from './Uris';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class EventService {

    private userToken: String;
    private user: any;
    constructor(public http: Http) {
        // console.log(localStorage.getItem('currentUser'));
        if (localStorage.getItem('currentUser')) {
            this.user = (<any>JSON.parse(localStorage.getItem('currentUser')).user) ? (<any>JSON.parse(localStorage.getItem('currentUser')).user) : (<any>JSON.parse(localStorage.getItem('currentUser')).aarc_user);
            // this.userToken = this.user.token
        }
    }
    
    getOne(obj) {
        return this.http.post(Uris.API_ENDPOINT + 'show_event', obj, this.jwt()).pipe(map((response: Response) => response.json()),catchError(this.handleError))
    }
    login(obj) {
        return this.http.post(Uris.API_ENDPOINT + 'login', obj, this.jwt()).pipe(map((response: Response) => response.json()),catchError(this.handleError))
    }
    sign_up(obj) {
        return this.http.post(Uris.API_ENDPOINT + 'sign_up', obj, this.jwt()).pipe(map((response: Response) => response.json()),catchError(this.handleError))
    }
    myFacturationData(obj) {
        return this.http.post(Uris.API_ENDPOINT + 'my_facturation_data', obj, this.jwt()).pipe(map((response: Response) => response.json()),catchError(this.handleError))
    }
    addFacturationData(obj) {
        return this.http.post(Uris.API_ENDPOINT + 'add_facturation_data', obj, this.jwt()).pipe(map((response: Response) => response.json()),catchError(this.handleError))
    }
    bulkRegister(obj) {
        return this.http.post(Uris.API_ENDPOINT + 'bulk_register', obj, this.jwt()).pipe(map((response: Response) => response.json()),catchError(this.handleError))
    }
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };

    errorHandler(error: any): void {
        console.log("SUPER ERROR", error)

        if (localStorage.getItem('currentUser') && error.status == 401) {
            localStorage.removeItem('currentUser');
            location.replace('login')
        }
    }

    private jwt() {
        if (this.userToken) {
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            return new RequestOptions({ headers: headers });
        }
    }

}
